<template>
    <div>
      <head-layout  head-title="主子查询"  :head-btn-options="headBtnOptions" @head-add="addFn" @head-romve="deleteFn">
      </head-layout>
      <!-- <el-container class="new-sino-box"> -->

        <!-- <el-header class="new-sino-header"> -->
          <div class="headers" style="display: flex;">
            <el-input placeholder="名称" v-model="queryList.name" class="new-sino-input" size="mini" style="width:200px"/>
            <el-input placeholder="编码" v-model="queryList.code" class="new-sino-input" size="mini" style="width:200px;margin-left: 10px;"/>
            <el-button-group style="margin-left: 10px;">
              <el-button size="mini" type="primary" icon="el-icon-search" @click="onLoad"></el-button>
            <el-button size="mini"  icon="reset-refresh icon-refresh" @click="czFn"></el-button>
            </el-button-group>

          </div>



        <!-- </el-header> -->

        <grid-layout
              ref="gridLayout"
              :data-total="total"
              @page-size-change="handleSizeChange"
              @page-current-change="handleCurrentChange"
              @page-refresh-change="onLoad"
              :page="page"
              @gird-handle-select-click="handleSelectionChange"
              :tableOptions="tableOptions"
              :tableData="tableData"
              :tableLoading="tableLoading"
              :gridRowBtn="gridRowBtn"
              @grid-edit="handleEdit"
              @grid-romve="delRow"
              :searchColumns="searchColumns"
            >

            </grid-layout>



    </div>
  </template>

  <script>
  import {
    getMarketApiResult,
    deleteMarketApiResult,
    tokenInitcode,
  } from "@/api/dataAcquisition/index";
  import { mapGetters } from "vuex";
  import website from '@/config/website';
  import HeadLayout from "@/views/components/layout/head-layout"
  import GridLayout from "@/views/components/layout/grid-layout";
  import dialogHeadBtn from "@/views/components/layout/dialog-head-btn";
  import formLayout from '@/views/components/layout/form-layout'
  import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
  export default {
    components: {
      HeadLayout,
      GridLayout,
      dialogHeadBtn,
      formLayout
    },
    data() {
      return {
        gridRowBtn: [
          {
            label: this.$t('cip.cmn.btn.editBtn'),
            emit: "grid-edit",
            remark: 'edit',
            type: "text",
            icon: ""
          },
          {
            label: this.$t('cip.cmn.btn.delBtn'),
            emit: "grid-romve",
            remark: 'remove',
            type: "text",
            icon: ""
          },],
          tableOptions: {
          customRowAddBtn: false,
          menuWidth: 100,
          column: [
          {
              label: "名称",
              prop: "name",
              cell: true,
              width:200,
            },
            {
              label: "编号",
              prop: "id",
              cell: true,
              width:200
            },


            {
              label: "编码",
              prop: "code",
              cell: true,

            },

            {
              label: "请求参数",
              prop: "marketApiReqName",
              cell: true,

            },


            {
              label: "创建时间",
              prop: "createTime",
              cell: true,
            },
            {
              label: "主服务名",
              prop: "marketApiName",
              cell: true,
            },

          ],
        },
        page: {
          pageSize: 20,
          currentPage: 1,
          total: 0
        },
        tableLoading:false,
        multipleSelection:[],
        tableData: [],
        total:0,
        queryList:{
            pageNum:1,
            pageSize:20,
            name:"",
            code:"",

        },
        loading:false,
        headBtnOptions: [{
          label: this.$t("cip.cmn.btn.addBtn"),
          emit: "head-add",
          type: "button",
          icon: ""
        },
        //  {
        //   label: this.$t("cip.cmn.btn.delBtn"),
        //   emit: "head-romve",
        //   type: "button",
        //   icon: ""
        // }
      ],
      };
    },
    computed: {
      ...mapGetters(["userInfo", "permission"]),
      permissionList() {
        return {
          addBtn: this.vaildData(this.permission.dept_add, false),
          viewBtn: this.vaildData(this.permission.dept_view, false),
          delBtn: this.vaildData(this.permission.dept_delete, false),
          editBtn: this.vaildData(this.permission.dept_edit, false)
        };
      },
    },
    methods: {
      onLoad(page, params = {}) {
        this.loading = true;
        getMarketApiResult(this.queryList).then(res => {
          this.loading = false;
          this.loading = false;

          this.tableData = res.data.data.data
          this.total = res.data.data.total
        });
      },
      getAutoCodeFn(){
        tokenInitcode().then((res)=>{
          console.log(res);
          this.form.infCode = res.data.data;
        })
      },
      addFn(){
        this.$router.push({
          path: '/business/datacenter/datacenter/market/MasterOrSub/add',
          query: {
            type: 'add',
          }
        })
      },
      handleEdit(item){
        this.$router.push({
          path: '/business/datacenter/datacenter/market/MasterOrSub/add',
          query: {
            type: 'edit',
            id:item.id
          }
        })
      },
      delRow(e){
          let ids = []
          // ids[0] = e.id


          this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), this.$t("cip.cmn.btn.delBtn"), {
            confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
            cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
            type: 'warning'
          }).then(() => {

            deleteMarketApiResult({id:e.id}).then((res)=>{
              this.$message.success(this.$t("cip.cmn.msg.success.delSuccess"));
              this.onLoad()
            })
          }).catch(() => {

          });
        },
      deleteFn(){
        if(this.multipleSelection.length == 0){
          this.$message.error(this.$t('cip.cmn.msg.warning.tipDetrmineSelectDel'))
          return
        }
        let ids = this.multipleSelection.map((item)=>{
          return item.id
        })
        this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), this.$t("cip.cmn.btn.delBtn"), {
            confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
            cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
          type: 'warning'
        }).then(() => {
          deleteMarketApiResult(ids).then((res)=>{
            this.$message.success(this.$t("cip.cmn.msg.success.delSuccess"));
            this.onLoad()
          })
        }).catch(() => {

        });

      },
      czFn(){
        this.queryList.name = '';
        this.queryList.code = '';
        this.queryList.pageNum = 1
        this.queryList.pageSize = 20
        this.onLoad()
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },


      handleSizeChange(val) {
        this.page.pageSize = val.pageSize
        this.queryList.pageNum = 1
        this.queryList.pageSize = val.pageSize;
        this.onLoad()
      },
      handleCurrentChange(val) {
        this.page.currentPage = val.currentPage
        this.queryList.pageNum = val.currentPage;
        this.onLoad()
      },

    },
    created() {
      this.onLoad();
    },
    mounted() {
    },
  };
  </script>
  <style scoped>
  .headers{
    background: #FFFFFF;
    padding: 14px 12px;
  }
  .headers ::v-deep .el-button{
    height: 28px;
  }
  ::v-deep .el-button+.el-button {
    margin-left: 0px;
  }
  </style>


